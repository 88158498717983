<template>
  <b-card>
    <b-row
      align-h="end"
      class="mr-0"
      style="gap: 1rem;"
    >
      <download-em-excel
        :filtro="filtroAtual"
        tipo="CNDs Municipal"
        :api="apiMunicipal"
      />
      <download-em-lote
        tipo-arquivo="todas as CND's Municipais"
        tipo="CND Municipal"
        :filtro="filtroAtual.filtroBy"
        :palavras-filtro="palavrasPersonalizadas"
        @baixar-lote="baixarEmLote"
      />
    </b-row>
    <cnd-municipal-list
      ref="cndMunicipalList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :tem-filtro-extra="true"
      :filtros-rapidos="filtrosRapidos"
    >
      <template slot="filtro-extra">
        <b-row>
          <b-col cols="5">
            <filtro-rapido
              :filtros="filtrosEstados"
              filtro-nome="UF"
              @selecionado="aplicarFiltroUF($event)"
            />
          </b-col>
          <b-col cols="7">
            <filtro-rapido
              ref="filtroRapidoMunicipio"
              :filtros="filtrosMunicipios"
              filtro-nome="Municipio"
              @selecionado="$refs.cndMunicipalList.aplicarFiltroRapido({...$event, colunas: ['MunicipioIbge'] }, false)"
            />
          </b-col>
        </b-row>
      </template>
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <b-badge
          v-if="props.column.field === 'situacaoDesc'"
          :variant="getColorTag(props.row[props.column.field])"
        >
          {{ props.row[props.column.field] }}
        </b-badge>
        <span
          v-if="props.column.field === 'razaoSocial'"
          @click.stop="$refs[`acoes${props.row.id}`].abrirArquivo(props.row)"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <cnd-acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :api="apiMunicipal"
        />
      </template>
    </cnd-municipal-list>
  </b-card>
</template>

<style lang="scss">
.modal-lg {
  max-width: 1000px!important;
}

.data-width{
  width: 120px!important;
}

.tamanho-cpf {
  min-width: 180px!important;
}

.em-manutencao {
  background: #ffc085;
}
</style>

<script>

import siglaEstados from '@/app/siglas-estados'
import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'CndMunicipalVisualizacao',
  components: {
    CndMunicipalList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    CndAcoes: () => import('@/app/shared/components/cnd-acoes/CndAcoes.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    FiltroRapido: () => import('@pilar/components/visualizacao-base/components/filtro-rapido/FiltroRapido.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
  },
  data() {
    return {
      apiMunicipal: api,
      filtrosEstados: siglaEstados,
      filtrosMunicipios: [],
      botoesOption: null,
      linha: null,
      visualizacao: {
        acaoClass: 'col-td-acao-3',
        disabledCheckbox: true,
        placeholderBusca: 'Buscar por Razão Social, CNPJ/CPF, UF, Município ou datas',
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'UF', 'Municipio', 'UltimaConsulta', 'DataValidade', 'ProximaConsulta'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Regular',
          colunas: ['Situacao'],
          label: 'Regulares',
          valor: '1,2',
        },
        {
          id: 'Irregular',
          colunas: ['Situacao'],
          label: 'Irregulares',
          valor: '3',
        },
        {
          id: 'Motivo',
          colunas: ['Situacao'],
          label: 'Motivo não geração',
          valor: '4, 5',
        },
        {
          id: 'EmManutencao',
          colunas: ['Situacao'],
          label: 'Em manutenção',
          valor: '5',
        },
      ],
      criando: true,
      aplicando: false,
      filtroAtual: {},
    }
  },
  computed: {
    palavrasPersonalizadas: {
      get() {
        const estados = this.filtrosEstados.map(map => map.label)
        return [['Regulares', 'Irregulares', 'Motivo'], [...estados]]
      },
    },
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.evento = this.carregar
  },
  mounted() {
    this.aplicarFiltroQuery()
    this.getMunicipiosLista()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      this.getMunicipiosLista()
      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.cndMunicipalList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.cndMunicipalList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    getColorTag(value) {
      if (value === 'Regular') {
        return 'light-success'
      }
      return value === 'Irregular' ? 'light-danger' : 'light-primary'
    },
    baixarEmLote(evt) {
      api.baixaEmLote(this.filtroAtual, evt)
    },
    getMunicipiosLista() {
      api.getMunicipioLista(this.filtroAtual)
        .then(payload => {
          this.filtrosMunicipios = []
          this.filtrosMunicipios = payload.data.map(p => ({ label: p.nome, valor: p.ibge, classe: p.status !== 1 ? '' : 'em-manutencao' }))
        })
        .catch(error => {
          console.error(error)
        })
    },
    aplicarFiltroUF(filtro) {
      const estadoFiltroAtural = this.filtroAtual.filtroBy.filter(s => s.colunas[0] === 'Estado')
      if (filtro !== null && filtro.label !== estadoFiltroAtural.label) {
        const index = this.filtroAtual.filtroBy.findIndex(s => s.colunas[0] === 'MunicipioIbge')
        if (index >= 0) {
          this.filtroAtual.filtroBy.splice(index, 1)
          this.$refs.filtroRapidoMunicipio.selecionado = null
        }
      }
      this.$refs.cndMunicipalList.aplicarFiltroRapido({ ...filtro, colunas: ['Estado'] }, false)
    },
  },
}
</script>
