export default [{
  label: 'Razão social', field: 'razaoSocial', type: 'custom',
},
{ label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'tamanho-cpf' },
{ label: 'UF', field: 'estado' },
{ label: 'Município', field: 'municipio' },
{ label: 'Situação', field: 'situacaoDesc', type: 'custom' },
{ label: 'Consultado em', field: 'ultimaConsulta', thClass: 'col-nowrap' },
{ label: 'Próxima consulta', field: 'proximaConsulta' },
{ label: 'Data da validade', field: 'validade' }]
