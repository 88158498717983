import axios from '@axios'

const PREFIX = 'cnd-municipais'

export default {

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getURL(id) {
    return axios.get(`${PREFIX}/${id}/url`)
  },

  getObservacoes(id) {
    return axios.get(`${PREFIX}/${id}/observacao`)
  },

  removerObservacao(id) {
    return axios.delete(`${PREFIX}/observacao/${id}/remover`)
  },

  salvarObservacao(observacao) {
    return axios.post(`${PREFIX}/observacao/salvar`, observacao)
  },

  notificaCnd(nomeEmpresa, emailEmpresa, emailCc, id, mensagemAdicional, definirEmailPadrao) {
    return axios.put(`${PREFIX}/${id}/notifica?emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`)
  },

  baixaEmLote(filtros, nomeArquivo) {
    return axios.post(`${PREFIX}/baixa-lotes?nomeArquivo=${nomeArquivo}`, filtros)
  },

  getMunicipioLista(filtros) {
    return axios.post(`${PREFIX}/municipios`, filtros)
  },

  baixarEmExcel(tipoCnd, filtros) {
    return axios.post(`${PREFIX}/${tipoCnd}/baixa-excel`, filtros)
  },

  deletarExcel(url, nomeArquivo) {
    axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },
}
